.footer {
    padding: 64px 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    color: rgba(34, 35, 38, 0.8);
    background-color: rgba(249, 250, 251, 1);
    .curson-pointer {
        cursor: pointer;
    }
    .left {
        .logo {
            height: 38px;
            width: 121px;
        }
        .left-container {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            span {
                line-height: 30px;
            }
        }
    }
    .center {
        .center-title {
            height: 30px;
            line-height: 30px;
            font-weight: 500;
            font-size: 18px;
        }
        .center-container {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            span {
                line-height: 30px;
            }
        }
    }
    .right {
        .right-title {
            height: 30px;
            line-height: 30px;
            font-weight: 500;
            font-size: 18px;
        }
        .code-box {
            margin-top: 17px;
            display: flex;
            .code {
                width: 124px;
                height: 156px;
                border: 1px solid rgba(23, 15, 73, 0.2);
                border-radius: 8px;
                text-align: center;
                .code-img {
                    margin-top: 12px;
                    width: 100px;
                    height: 100px;
                }
                .code-title {
                    width: 124px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                }
            }
        }
    }
    .m-r-16 {
        margin-right: 16px;
    }
}