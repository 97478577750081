.home {
    .m-b-40 {
        margin-bottom: 40px;
    }
    .translation-r-20 {
        transform: translateX(40px)
    }
    margin-top: -72px;
    .first {
        margin-top: -18px;
        position: relative;
        display: flex;
        justify-content: center;
        .first-title-box {
            position: absolute;
            top: 95px;
            z-index: 3;
            margin: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .first-desc {
                .label {
                    font-family: Open Sans;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0.4em;
                    text-align: center;
                    color: rgba(85, 179, 255, 1);
                }
                .title {
                    margin-top: 18px;
                    margin-bottom: 18px;
                    font-family: Dream Han Sans CN;
                    font-size: 60px;
                    font-weight: 900;
                    line-height: 62.41px;
                    letter-spacing: 2px;
                    text-align: center;
                    color: #fff
                }
                .desc {
                    font-family: Dream Han Sans CN;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 25.2px;
                    letter-spacing: 0.1em;
                    text-align: center;
                    color: #fff;
                }
            }
            .button {
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 693;
                line-height: 20px; /* 125% */
                letter-spacing: 0.32px;
                background: linear-gradient(90deg, #0696FF 0%, #4060FF 100%);
                box-shadow: 0px 30px 50px 0px rgba(40, 120, 255, 0.30);
                font-size: 16px;
                color: #fff;
                margin-top: 50px;
                width: 173px;
                height: 48px;
                border-radius: 999px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    margin-left: 8px;
                }
            }
            
        }
        .gif-box {
            width: 100%;
        }
    }
    .second {
        margin-top: -12px;
        padding: 96px 120px;
        background-color: rgba(249, 250, 255, 1);
        display: flex;
        justify-content: space-between;
        .second-left-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .second-title {
                font-size: 36px;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);
                line-height: 52px;
            }
            .second-desc {
                margin-top: 12px;
                line-height: 24px;
                font-size: 20px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        
        .second-right-box {
            .second-content {
                box-sizing: border-box;
                background-color: rgba(255, 255, 255, 0.6);
                border-radius: 16px;
                padding: 32px;
                box-shadow: 0 10px 20px 0 rgba(208, 213, 225, 0.2);
                cursor: pointer;
                &:hover {
                    background-color: rgba(255, 255, 255, 1);
                }
                .second-content-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-weight: 587;
                    font-size: 22px;
                    line-height: 26px;
                    color: rgba(23, 15, 73, 1);
                    padding-bottom: 21px;
                    img {
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                    }
                }
                .second-content-desc {
                    text-align: justify;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 32px;
                    color: rgba(23, 15, 73, 0.8);
                }
            }
        }
        
    }
    .third {
        padding: 0 108px;
        margin-top: 96px;
        .third-title {
            .title {
                font-weight: 749;
                font-size: 36px;
                line-height: 52px;
                text-align: center;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                margin-top: 12px;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        .third-content {
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            .flex-box {
                display: flex;
            }
            .item {
                flex: 1;
                cursor: pointer;
                margin-top: 100px;
                position: relative;
                background-color: rgba(249, 250, 251, 1);
                padding: 64px 24px 24px 24px;
                border-radius: 16px;
                text-align: justify;
                // width: 360px;
                // height: 257px;
                &:hover {
                    background-color: rgba(255, 255, 255, 1);
                    box-shadow: 0px 12px 16px -4px rgba(137,140,149,0.08), 0px 4px 4px 0px rgba(137,140,149,0.03);
                }
                .icon-box {
                    width: 64px;
                    height: 64px;
                    position: absolute;
                    left: 32px;
                    top: -32px;
                    background-color: rgba(255, 255, 255, 1);
                    box-shadow: 4px 8px 20px 0 rgba(107, 130, 190, 0.2);
                    border-radius: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 44px;
                        height: 44px;
                    }
                }
                .title {
                    font-weight: 693;
                    margin-bottom: 20px;
                    font-weight: 693px;
                    font-size: 24px;
                    line-height: 28.8px;
                    color: rgba(23, 15, 73, 1);
                }
                .desc {
                    font-size: 14px;
                    line-height: 28px;
                    color:rgba(23, 15, 73, 0.8);
                }
            }
        }
    }
    .fourthly {
        margin-top: 96px;
        box-sizing: border-box;
        height: 580px;
        background-image: url('../../imgs//new/bg1.svg');
        background-repeat: no-repeat;
        background-size: cover;
        .fourthly-title {
            padding-top: 96px;
            padding: 88px;
            .title {
                font-size: 36px;
                font-style: normal;
                font-weight: 749;
                line-height: 52px;
                color: rgba(23, 15, 73, 1);
                text-align: center;
            }
            .desc {
                font-size: 20px;
                font-weight: 443;
                line-height: normal;
                color: rgba(23, 15, 73, 0.6);
                text-align: center;
            }
        }
        .fourthly-content {
            display: flex;
            justify-content: center;
            .m-r-24 {
                margin-right: 24px;
            }
            .item {
                box-sizing: border-box;
                width: 486px;
                height: 284px;
                background-color: rgba(255, 255, 255, 0.6);
                padding: 48px;
                cursor: pointer;
                border-radius: 12px;
                fill: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100.28%);
                stroke-width: 2px;
                stroke: rgba(255, 255, 255, 0.80);
                filter: drop-shadow(0px 8px 80px #F0F4FF);
                backdrop-filter: blur(10px);
                &:hover{
                    background-color: rgba(255, 255, 255, 1);
                    box-shadow: 0px 8px 80px #F0F4FF;
                }
                .title {
                    color: #170F49;
                    font-size: 24px;
                    font-weight: 693;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                    }
                }
                .desc {
                    color: rgba(23, 15, 73, 0.80);
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-size: 20px;
                    font-weight: 398;
                }
                .list {
                    .list-item-box {
                        display: flex;
                        justify-content: flex-start;
                        .list-item {
                            color: rgba(23, 15, 73, 0.80);
                            font-weight: 398;
                            font-size: 12px;
                            padding: 10px 16px;
                            border-radius: 999px;
                            border: 0.5px solid rgba(23, 15, 73, 0.40);
                            &:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .fifth {
        margin-bottom: 140px;
        .fifth-title-box {
            margin-top: 136px;
            margin-bottom: 60px;
            .title {
                font-size: 36px;
                font-style: normal;
                font-weight: 749;
                line-height: 52px;
                color: rgba(23, 15, 73, 1);
                text-align: center;
                margin-bottom: 12px;
            }
            .desc {
                font-size: 20px;
                font-style: normal;
                font-weight: 443;
                color: rgba(23, 15, 73, 0.6);
                text-align: center;
            }
        }
        .swiper-box {
            width: 364px;
            display: inline-block;
            scroll-snap-align: center;
            // height: 480px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .swiper-img {
                width: auto;
                width: 364px;
                height: 200px;
            }
            .swiper-content {
                position: relative;
                top:-40px;
                border-radius: 12px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.90) 0%, #FFF 100%);
                backdrop-filter: blur(6.5px);
                box-sizing: border-box;
                width: 364px;
                padding: 20px;
                box-shadow: 0px 12px 16px -4px rgba(137, 140, 149, 0.08);
                .title {
                    border-radius: 4px;
                    width: 48px;
                    background: rgba(64, 96, 255, 0.08);
                    color: rgba(64, 96, 255, 1);
                    font-size: 12px;
                    padding: 8px;
                    line-height: 12px;
                }
                .desc {
                    font-size: 16px;
                    font-weight: 489;
                    line-height: 26px;
                    color: rgba(23, 15, 73, 1);
                    margin: 12px 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .time {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: rgba(23, 15, 73, 0.6);
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}