.page-header {
    box-sizing: border-box;
    height: 72px;
    padding: 0 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    .logo-box {
        display: flex;
        align-items: center;
    }
    .title-link-box {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title-link {
            display: inline;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            &:not(:last-child) {
                padding-right: 32px;
            }
        }
        .active {
            font-weight: 600;
        }
        .title-btn {
            color: #ffffff;
            background-color: #4060FF;
            padding: 10px 20px;
            border-radius: 80px;
            box-shadow: 0px 2px 20px 0px rgba(64, 96, 255, 0.40);
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px; /* 125% */
        }
    }
}