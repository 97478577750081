.mobile-footer {
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    color: rgba(34, 35, 38, 0.8);
    background-color: rgba(249, 250, 251, 1);
    margin-bottom: 85px;
    .curson-pointer {
        cursor: pointer;
    }
    .left {
        width: 100%;
        font-size: 12px;
        .left-container {
            .text-center {
                text-align: center;
            }
            .m-b-t-10 {
                margin: 10px 0;
            }
            .record {
                width: 100%;
                display: flex;
                justify-content: center;
                .first-record {
                    margin-right: 10px;
                }
            }
        }
    }
    .center {
        .center-title {
            display: block;
            font-weight: 500;
            font-size: 11px;
            color: #222326;
            margin-bottom: 16px;
        }
        .center-container {
            font-size: 11px;
            margin-top: 0px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            span {
                display: inline-block;
                margin-bottom: 10px;
                line-height: 15px;
            }
        }
    }
    .right {
        .right-title {
            margin-top: 12px;
            margin-bottom: 16px;
            display: block;
            font-weight: 500;
            font-size: 11px;
            color: #222326;
        }
        .code-box {
            margin-top: 0;
            display: flex;
            .code {
                width: 65px;
                height: 90px;
                border: 1px solid rgba(23, 15, 73, 0.2);
                border-radius: 8px;
                text-align: center;
                .code-img {
                    margin-top: 12px;
                    width: 50px;
                    height: 50px;
                }
                .code-title {
                    text-align: center;
                    line-height: 30px;
                    font-size: 8px;
                    line-height: 20px;
                }
            }
        }
    }
    .m-r-16 {
        margin-right: 16px;
    }
}