.mobile-core-product {
    .core-header {
        margin-top: -72px;
        position: relative;
        height: 390px;
        width: 100%;
        background-image: linear-gradient(to right, #E6F1FD, #E1E5EE);
        .core-header-img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 200px;
            height: 200px;
        }
        .core-header-content {
            padding: 102px 16px 0 16px;
            .title {
                padding-bottom: 16px;
                font-size: 20px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                width: 100%;
                font-size: 13px;
                font-weight: 443;
                line-height: 23px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
    }
    
    .product-second {
        background: linear-gradient(102.89deg, rgba(247, 248, 255, 0.6) 5.56%, rgba(229, 241, 255, 0.6) 45.39%, rgba(235, 235, 255, 0.6) 62.96%, rgba(248, 244, 255, 0.6) 100.08%);
        padding: 60px 16px;
        .title-box {
            margin-bottom: 36px;
            .title {
                text-align: center;
                font-size: 18px;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                margin-top: 12px;
                text-align: center;
                font-size: 13px;
                color: rgba(23, 15, 73, 0.6);
                font-weight: 400;
                line-height: 23px; /* 200% */
            }
        }
        .content-box {
            .flex-box {
                display: flex;
            }
            .item {
                flex: 1;
                cursor: pointer;
                padding: 12px;
                border-radius: 8px;
                border: 1px solid #ffffff;
                background: rgba(255, 255, 255, 0.60);
                box-shadow: 0px 10px 20px 0px rgba(208, 213, 225, 0.20);
                // &:hover {
                //     background: #ffffff;
                //     box-shadow: 0px 10px 20px 0px rgba(208, 213, 225, 0.20);
                // }
                .title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 21px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        margin-left: 6px;
                        font-size: 15px;
                        font-weight: 693;
                        color: rgba(23, 15, 73, 1);
                    }
                }
                .desc {
                    display: flex;
                    align-items: center;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    img {
                        width: 6px;
                        height: 5px;
                    }
                    span {
                        font-size: 12px;
                        font-weight: 398;
                        color: rgba(23, 15, 73, 1);
                        margin-left: 4px;
                    }
                }
                .expect {
                    font-size: 12px;
                    font-weight: 398;
                    color: rgba(23, 15, 73, 1);
                    margin-top: 21px;
                }
                .other {
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 15px;
                        font-weight: 587;
                        color: rgba(23, 15, 73, 1);
                        margin-right: 8px;
                    }
                    img {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
    .product-third {
        padding: 0 16px;
        .title {
            margin-top: 44px;
            margin-bottom: 31px;
            text-align: center;
            font-size: 18px;
            font-weight: 749;
            color: rgba(23, 15, 73, 1);
        }
        .panel-box {
            .panel {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .content {
                    // width: 500px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title-p {
                        .title {
                            font-size: 16px;
                            font-weight: 749;
                            color: #4060FF;
                        }
                        .agents {
                            font-size: 16px;
                            font-weight: 700;
                            color: rgba(23, 15, 73, 1);
                        }
                    }
                    .subhead {
                        margin-top: 10px;
                        margin-bottom: 16px;
                        font-size: 14px;
                        font-weight: 693;
                        color: rgba(23, 15, 73, 1);;
                    }
                    .desc {
                        text-align: justify;
                        margin-top: 16px;
                        font-size: 13px;
                        font-weight: 443;
                        line-height: 23px;
                        color: rgba(23, 15, 73, 0.6);
                    }
                }
                .img-box {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
