.mobile-about-us {
    .about-header {
        margin-top: -72px;
        box-sizing: border-box;
        padding: 102px 16px 30px 16px;
        position: relative;
        height: 360px;
        width: 100%;
        background: linear-gradient(to bottom, #E6EEF6, #E6F3FF);
        .about-header-img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 200px;
            height: 200px;
        }
        .about-header-content {
            .title {
                font-size: 20px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                margin-top: 16px;
                font-size: 13px;
                font-weight: 443;
                color: rgba(23, 15, 73, 0.6);
            }
            .button {
                font-size: 30px;
                color: #fff;
                background: linear-gradient(90deg, #0696FF 0%, #4060FF 100%);
                margin-top: 50px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
    .about-us-introduce {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0 auto 600px auto;
        top: -10px;
        width: 318px;
        height: 233px;
        img {
            width: 318px;
            height: 233px;
        }
        .about-us-introduce-left-font-box {
            position: absolute;
            left: 25px;
            top: 24px;
            width: 270px;

            .m-tb-20 {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .left-font-item {
                color: #ffffff;
                text-align: justify;
                .label {
                    font-family: Dream Han Sans CN;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    letter-spacing: 1px;
                    text-align: left;
                }
                .desc {
                    font-family: Dream Han Sans CN;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 23px;
                    text-align: justify;
                }
            }
        }
        .about-us-introduce-right-font-box {
            margin: auto;
            box-sizing: border-box;
            position: absolute;
            right: 0;
            left: 0;
            top: 230px;
            padding: 20px 18px;
            width: 318px;
            color: rgba(23, 15, 73, 0.8);
            font-family: Dream Han Sans CN;
            font-size: 13px;
            font-weight: 398;
            line-height: 25px;
            text-align: justify;
            border: 0.5px solid;
            border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            box-shadow: 0 10px 20px 0 rgba(208, 213, 225, 0.2);
            border-radius: 12px;

        }
    }
    .enterprise-honor {
        margin-top: 40px;
        padding: 0 16px;
        margin-bottom: 36px;
        .title {
            text-align: center;
            font-size: 18px;
            font-weight: 749;
            color: rgba(23, 15, 73, 1);
        }
        .desc {
            margin-top: 8px;
            font-size: 13px;
            font-weight: 443;
            line-height: 23px;
            text-align: center;
            color: rgba(23, 15, 73, 0.6);
        }
    }
    .swiper-box {
        width: 155px;
        display: inline-block;
        scroll-snap-align: center;
        cursor: pointer;
        border-radius: 16px;

        .swiper-img {
            width: 155px;
            border-radius: 16px;
        }
        .swiper-content {
            text-align: center;
            .title {
                font-size: 14px;
                font-weight: 693;
                color: rgba(23, 15, 73, 1);
            }
            .time {
                margin-bottom: 8px;
                font-size: 12px;
                font-weight: 693;
                color: rgba(64, 96, 255, 1);
            }
        }
    }
    .core-team {
        padding: 0 120px;
        .title {
            text-align: center;
            font-size: 36px;
            font-weight: 749;
            line-height: 52px;
            color: rgba(23, 15, 73, 1);
            margin-bottom: 72px;
        }
        .person {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 120px;
            .introduce {
                flex: 1;
                .top {
                    margin-bottom: 30px;
                    .name {
                        color: #170F49;
                        font-size: 32px;
                        font-weight: 749;
                    }
                    .job-title {
                        margin-left: 16px;
                        font-size: 20px;
                        font-weight: 749;
                        color: rgba(64, 96, 255, 1);
                    }
                }
                .tag-box {
                    display: flex;
                    flex-wrap: wrap;
                    .tag {
                        margin-bottom: 12px;
                        display: inline-block;
                        font-weight: 443;
                        font-size: 14px;
                        color: #001781;
                        padding: 8px 16px;
                        border-radius: 99px;
                        background: rgba(64, 96, 255, 0.08);
                        &:not(:last-child) {
                            margin-right: 16px;
                        }
                    }
                }
                .desc {
                    padding-top: 12px;
                    color: rgba(23, 15, 73, 1);
                    font-size: 16px;
                    font-weight: 443;
                    line-height: 200%;
                }
            }
            .avater {
                width: 485px;
                height: 264px;
                border-radius: 16px;
                // background: #DDE2EC;
            }
            .m-l-60 {
                margin-left: 60px;
            }
            .m-r-60 {
                margin-right: 60px;
            }
        }
    }
    .technical-team {
        padding: 0 16px;
        margin-bottom: 36px;
        .title {
            text-align: center;
            font-size: 18px;
            font-weight: 749;
            color: rgba(23, 15, 73, 1);
            margin-bottom: 12px;
        }
        .desc {
            text-align: center;
            font-size: 13px;
            font-weight: 443;
            line-height: 23px;
            color: rgba(23, 15, 73, 0.6);
        }
    }
    .company-logo {
        margin-bottom: 60px;
        padding: 0 16px;
        .col-img-6 {
            width: 100%;
            height: auto;
        }
        // box-sizing: border-box;
        // margin-bottom: 60px;
        // padding: 0 16px;
        // display: flex;
        // flex-direction: column;
        // flex-wrap: nowrap;
        // justify-content: flex-start;
        // .swiper {
        //     .swiper-wrapper {
        //         .swiper-slide {
        //             height: 178px;
        //             width: 240px;
        //             display: flex;
        //             justify-content: center;
                    
        //             &:nth-child(even) {
        //                 align-items: flex-end;
        //             }
        //             &:nth-child(odd) {
        //                 align-items: flex-start;
        //             }
        //         }
        //     }
        // }
        .school {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            .logo-box {
                width: 50%;
                img {
                    width: 100%;
                    height: auto;
                }
                &:last-child{
                    width: 100%;
                    text-align: center;
                    img {
                        width: 50%;
                    }
                }
            }
            
        }
        .company {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            .logo-box {
                width: 50%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
.ant-modal-root {
    .about-us-modal-video {
        .ant-modal-close {
            top: 2px;
            right: 4px !important;
        }

    }
}