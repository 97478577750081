.core-product {
    .core-header {
        position: relative;
        height: 463px;
        width: 100%;
        background-image: linear-gradient(to bottom, #F0F1F5, #E1E5EE);
        .core-header-img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .core-header-content {
            position: absolute;
            top: 88px;
            left: 120px;
            .title {
                font-size: 40px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                width: 480px;
                margin-top: 16px;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
    }
    
    .product-second {
        background: linear-gradient(102.89deg, rgba(247, 248, 255, 0.6) 5.56%, rgba(229, 241, 255, 0.6) 45.39%, rgba(235, 235, 255, 0.6) 62.96%, rgba(248, 244, 255, 0.6) 100.08%);
        padding: 96px 120px;
        .title-box {
            margin-bottom: 80px;
            .title {
                text-align: center;
                font-size: 32px;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);

                
            }
            .desc {
                margin-top: 12px;
                text-align: center;
                font-size: 18px;
                font-weight: 489;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
                font-weight: 400;
                line-height: 36px; /* 200% */
            }
        }
        .content-box {
            .flex-box {
                display: flex;
            }
            .item {
                flex: 1;
                cursor: pointer;
                padding: 24px 32px;
                border-radius: 16px;
                border: 2px solid #ffffff;
                background: rgba(255, 255, 255, 0.60);
                box-shadow: 0px 10px 20px 0px rgba(208, 213, 225, 0.20);
                &:hover {
                    background: #ffffff;
                    box-shadow: 0px 10px 20px 0px rgba(208, 213, 225, 0.20);
                }
                .title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 32px;
                    img {
                        width: 32px;
                        height: 32px;
                    }
                    span {
                        margin-left: 12px;
                        font-size: 22px;
                        font-weight: 693;
                        color: rgba(23, 15, 73, 1);
                    }
                }
                .desc {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    img {
                        width: 9px;
                        height: 7px;
                    }
                    span {
                        font-size: 18px;
                        font-weight: 398;
                        color: rgba(23, 15, 73, 1);
                        margin-left: 18px;
                    }
                }
                .expect {
                    font-size: 18px;
                    font-weight: 398;
                    color: rgba(23, 15, 73, 1);
                    margin-top: 18px;
                }
                .other {
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 20px;
                        font-weight: 587;
                        color: rgba(23, 15, 73, 1);
                        margin-right: 8px;
                    }
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .product-third {
        padding: 0 120px;
        .title {
            margin-top: 96px;
            margin-bottom: 60px;
            text-align: center;
            font-size: 32px;
            font-weight: 749;
            color: rgba(23, 15, 73, 1);
        }
        .tab-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            .tab {
                padding: 12px 20px;
                border-radius: 999px;
                border: 1px solid rgba(64, 96, 255, 0.08);
                background: #F7F7FC;
                display: inline-flex;
                .item {
                    padding: 12px 32px;
                    font-size: 18px;
                    font-weight: 489;
                    color: rgba(23, 15, 73, 1);
                }
                .active {
                    color: #ffffff;
                    border-radius: 99px;
                    background: #170F49;
                }
            }
        }
        .panel-box {
            .panel {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .content {
                    width: 500px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title-p {
                        margin-bottom: 12px;
                        .title {
                            font-size: 36px;
                            font-weight: 749;
                            line-height: 52px; 
                            color: #4060FF;
                        }
                        .agents {
                            font-size: 36px;
                            font-weight: 700;
                            line-height: 52px;
                            color: rgba(23, 15, 73, 1);
                        }
                    }
                    .subhead {
                        margin-bottom: 28px;
                        font-size: 24px;
                        font-weight: 693;
                        line-height: 36px;
                        color: rgba(23, 15, 73, 1);;
                    }
                    .desc {
                        text-align: justify;
                        font-size: 20px;
                        font-weight: 443;
                        line-height: 36px;
                        color: rgba(23, 15, 73, 0.6);
                    }
                }
                .img-box {
                    width: 690px;
                    height: 618px;
                }
            }
        }
    }
}
