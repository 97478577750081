.affix-button-group {
    position: fixed;
    bottom: 180px;
    right: 50px;
    z-index: 1000;
    border-radius: 999px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .button-box {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .button-icon {
            margin-bottom: 8px;
            width: 16px;
            height: 16px;
        }
        .button-label {
            width: 16px;
            color: #170F49;
            text-align: center;
            font-size: 14px;
            font-weight: 489;
        }
    }
}