.layout {
    .main {
        padding-top: 72px;
    }
    .iframe-box {
        position: fixed;
        z-index: 10000;
        bottom: 60px;
        right: 120px;
        height: 601px;
        width: 601px;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2);

        border-radius: 8px 8px 0 0;
        padding-top: 6px;
        .iframe-box-title {
            font-size: 16px;
            text-align: center;
            padding: 12px 0;
            color: #170F49;
            background-color: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(15px);
            border-bottom: 1px solid #E8F0FB;
            position: relative;
            .close-btn {
                position: absolute;
                top: 5px;
                right: 10px;
                cursor: pointer;
            }
        }
    }
    .mobile-footer-bar {
        box-shadow: 0px 0px 20px 0px rgba(137, 140, 149, 0.2);
        background-color: #fff;
        padding:16px 20px 28px 20px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        justify-content: space-between;
        .mobile-footer-bar-button {
            background-color: rgba(64, 96, 255, 0.08);
            color: rgba(64, 96, 255, 1);
            font-family: Dream Han Sans CN;
            font-size: 16px;
            font-weight: 587;
            line-height: 20px;
            text-align: center;
            display: flex;
            padding: 12px 16px 12px 16px;
            font-family: Dream Han Sans CN;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            border-radius: 999px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }
        }
    }
}
