.core-technology {
    .core-header {
        position: relative;
        height: 463px;
        width: 100%;
        background-image: linear-gradient(to bottom, #ffffff, #CFE7FD);
        .core-header-img {
            position: absolute;
            right: 0;
            bottom: 0;
            // width: 100%;
            // height: 100%;
            // object-fit: cover;
        }
        .core-header-content {
            position: absolute;
            top: 88px;
            left: 120px;
            .title {
                font-family: "Dream Han Sans CN";
                color: #170F49;
                letter-spacing: 1px;
                font-size: 40px;
                font-weight: 808;
            }
            .subhead {
                margin-top: 16px;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: 693;
                line-height: 34px;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                width: 60%;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
            .button {
                font-size: 16px;
                color: #fff;
                background: linear-gradient(90deg, #0696FF 0%, #4060FF 100%);
                margin-top: 50px;
                width: 173px;
                height: 48px;
                border-radius: 999px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    margin-left: 8px;
                    width: 21px;
                    height: 20px;
                }
            }
        }
    }
    .core-first {
        .top {
            margin: 90px 0;
            .title {
                text-align: center;
                font-size: 36px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .subhead {
                text-align: center;
                margin-top: 16px;
                margin-bottom: 32px;
                font-size: 18px;
                font-weight: 693;
                line-height: 34px;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                padding: 0 240px;
                text-align: center;
                margin-bottom: 32px;
                font-size: 18px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        .bottom {
            padding: 0 120px;
            .flex-box {
                display: flex;
            }
            .item {
                flex: 1;
                cursor: pointer;
                padding: 32px;
                border-radius: 16px;
                background: #F9FAFB;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                    width: 48px;
                    height: 48px;
                }
                p {
                    font-size: 16px;
                    line-height: 28px;
                    font-weight: 693;
                    color: rgba(23, 15, 73, 1);
                    margin-left: 26px;
                }
                &:hover {
                    background-color: rgba(255, 255, 255, 1);
                    box-shadow: 0px 12px 16px -4px rgba(137, 140, 149, 0.08), 0px 4px 4px 0px rgba(137, 140, 149, 0.03);
                }
            }
        }
    }
    .product-first {
        padding: 0 120px;
        .title-box {
            margin-top: 98px;
            margin-bottom: 72px;
            .title {
                text-align: center;
                font-size: 32px;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);
            }
        }
    }
    .core-second {
        margin: 90px 0;
        background: linear-gradient(180deg, #F9FAFF 0%, rgba(249, 250, 255, 0.00) 100%);
        .top {
            padding-top: 90px;
            .title {
                text-align: center;
                font-size: 36px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .subhead {
                text-align: center;
                margin-top: 16px;
                margin-bottom: 32px;
                font-size: 18px;
                font-weight: 693;
                line-height: 34px;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                padding: 0 240px;
                text-align: center;
                margin-bottom: 32px;
                font-size: 18px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        .bottom {
            .tab-box {
                display: flex;
                justify-content: center;
                align-items: center;
                .item {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #170F49;
                    font-size: 20px;
                    font-weight: 639;
                    line-height: 34px;
                    padding: 8px 18px;
                    &:nth-child(2) {
                        margin: 0 32px;
                    }
                    img {
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                    }
                }
                .active {
                    color: rgba(255, 255, 255, 1);
                    background-color: rgba(23, 15, 73, 1);
                    border-radius: 999px;
                }
            }
            .tab-panel {
                margin-top: 64px;
                .panel-item {
                    padding: 0 120px;
                    .flex-box {
                        display: flex;
                        margin-bottom: 32px;
                    }
                    .content {
                        flex: 1;
                        // height: 218px;
                        cursor: pointer;
                        padding: 24px 20px;
                        display:flex;
                        flex-direction: column;
                        // justify-content: center;
                        align-items: center;
                        border-radius: 16px;
                        background: var(--, #FFF);
                        box-shadow: 0px 12px 16px -4px rgba(137, 140, 149, 0.08), 0px 4px 4px 0px rgba(137, 140, 149, 0.03);
                        &:hover {
                            .hover-desc, .title {
                                color: rgba(64, 96, 255, 1);
                            }
                        }
                        .item-icon {
                            width: 44px;
                            height: 44px;
                        }
                        .title {
                            font-size: 18px;
                            font-weight: 693;
                            color: rgba(23, 15, 73, 1);
                            // margin-bottom: 20px;
                            margin-top: 32px;
                        }
                        .desc {
                            margin-top: 32px;
                            text-align: center;
                            font-size: 17px;
                            font-weight: 500;
                            line-height: 31px;
                            color: rgba(23, 15, 73, 0.8);
                        }
                    }
                }
            }
        }
    }
}

.ant-tabs.tab-box {
    .ant-tabs-tab-active {
        font-weight: 700 !important;
    }
}