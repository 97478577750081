.mobile-core-technology {
    .core-header {
        margin-top: 24px;
        box-sizing: border-box;
        position: relative;
        padding: 0 16px;
        height: 280px;
        width: 100%;
        background: linear-gradient(to bottom, #ffffff 0%, #E5F2FE 50%, #CFE7FD 100%);
        .core-header-img {
            position: absolute;
            // top: 0;
            right: 0;
            bottom: 0;
            // left: 0;
            width: 200px;
            height: 200px;

            // height: 200px;
            // width: 100%;
            // height: 100%;
            // object-fit: cover;
        }
        .core-header-content {
            position: absolute;
            .title {
                font-family: "Dream Han Sans CN";
                color: #170F49;
                letter-spacing: 1px;
                font-size: 20px;
                font-weight: 800;
            }
            .subhead {
                margin-top: 10px;
                margin-bottom: 16px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                margin-bottom: 16px;
                font-size: 13px;
                font-weight: 443;
                line-height: 23px;
                color: rgba(23, 15, 73, 0.6);
            }
            .button {
                width: 109px;
                height: 34px;
                font-size: 12px;
                color: #fff;
                background: linear-gradient(90deg, #0696FF 0%, #4060FF 100%);
                border-radius: 999px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    margin-left: 4px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    .core-first {
        .top {
            margin: 60px 0;
            .title {
                text-align: center;
                font-size: 18px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .subhead {
                text-align: center;
                margin-top: 8px;
                margin-bottom: 16px;
                font-size: 12px;
                font-weight: 693;
                line-height: 14px;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                padding: 0 28px;
                text-align: center;
                margin-bottom: 32px;
                font-size: 13px;
                font-weight: 443;
                line-height: 23px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        .bottom {
            padding: 0 16px;
            .flex-box {
                display: flex;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
            .item {
                height: 70px;
                flex: 1;
                cursor: pointer;
                padding: 10px 16px;
                border-radius: 16px;
                background: #F9FAFB;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                    width: 24px;
                    height: 24px;
                }
                p {
                    font-size: 13px;
                    line-height: 23px;
                    font-weight: 693;
                    color: rgba(23, 15, 73, 1);
                    margin-left: 26px;
                }
                // &:hover {
                //     background-color: rgba(255, 255, 255, 1);
                //     box-shadow: 0px 12px 16px -4px rgba(137, 140, 149, 0.08), 0px 4px 4px 0px rgba(137, 140, 149, 0.03);
                // }
            }
        }
    }
    .product-first {
        padding: 0 16px;
        .title-box {
            margin-top: 60px;
            margin-bottom: 30px;
            .title {
                text-align: center;
                font-size: 18px;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);
            }
        }
    }
    .core-second {
        margin: 70px 0 58px 0;
        background: linear-gradient(to bottom, #EEF2FF 0%, #ffffff 100%);
        .top {
            padding-top: 60px;
            .title {
                text-align: center;
                font-size: 18px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .subhead {
                text-align: center;
                margin-top: 6px;
                margin-bottom: 16px;
                font-size: 12px;
                font-weight: 693;
                line-height: 14px;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                padding: 0 16px;
                text-align: center;
                margin-bottom: 32px;
                font-size: 13px;
                font-weight: 443;
                line-height: 23px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        .bottom {
            .tab-box {
                display: flex;
                justify-content: center;
                align-items: center;
                .item {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #170F49;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 639;
                    padding: 7px 9px;
                    &:nth-child(2) {
                        margin: 0 32px;
                    }
                    img {
                        margin-right: 4px;
                        width: 16px;
                        height: 16px;
                    }
                }
                .active {
                    color: rgba(255, 255, 255, 1);
                    background-color: rgba(23, 15, 73, 1);
                    border-radius: 999px;
                }
            }
            .tab-panel {
                margin-top: 36px;
                .panel-item {
                    padding: 0 16px;
                    .flex-box {
                        display: flex;
                        margin-bottom: 12px;
                    }
                    .content {
                        flex: 1;
                        // height: 218px;
                        cursor: pointer;
                        padding: 8px;
                        display:flex;
                        flex-direction: column;
                        // justify-content: center;
                        align-items: center;
                        border-radius: 13px;
                        background: #ffffff;
                        box-shadow: 0px 12px 16px -4px rgba(137, 140, 149, 0.08), 0px 4px 4px 0px rgba(137, 140, 149, 0.03);
                        // &:hover {
                        //     .hover-desc, .title {
                        //         color: rgba(64, 96, 255, 1);
                        //     }
                        // }
                        .item-icon {
                            width: 22px;
                            height: 22px;
                        }
                        .title {
                            font-size: 16px;
                            font-weight: 693;
                            color: rgba(23, 15, 73, 1);
                            // margin-bottom: 20px;
                            margin-top: 16px;
                        }
                        .desc {
                            margin-top: 16px;
                            text-align: center;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 23px;
                            color: #170F49;
                        }
                    }
                }
            }
        }
    }
}

.ant-tabs.mobile-tab-box {
    width: 100% !important;
    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 20px !important;
    }
    .ant-tabs-tab-active {
        font-weight: 700 !important;
    }
}