.mobile-home {
    .m-b-20 {
        margin-bottom: 20px;
    }
    .translation-r-20 {
        transform: translateX(40px)
    }
    margin-top: -72px;
    .first {
        position: relative;
        display: flex;
        justify-content: center;
        background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 58%, rgba(255,255,255,0) 100%);
        .first-title-box {
            position: absolute;
            top: 88px;
            z-index: 3;
            margin: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 243px;
                height: 55px;
            }
            .first-title {
                .title {
                    font-size: 10px;
                    font-weight: bold;
                    color: rgba(85, 179, 255, 1);
                    text-align: center;
                }
                .subhead {
                    font-size: 23px;
                    color: #fff;
                    font-weight: 800;
                    margin: 8px 0 12px 0;
                }
                .desc {
                    font-size: 14px;
                    color: #fff;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
            .button {
                width: 109px;
                height: 34px;
                font-size: 12px;
                color: #fff;
                background: linear-gradient(90deg, #0696FF 0%, #4060FF 100%);
                border-radius: 999px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    margin-left: 4px;
                    width: 16px;
                    height: 16px;
                }
            }
            
        }
        .gif-box {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
    .second {
        margin-top: -6px;
        background-color: rgba(249, 250, 255, 1);
        display: flex;
        justify-content: space-between;
        padding-bottom: 40px;
        .second-left-box {
            padding-top: 40px;
            padding-bottom: 22px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .second-title {
                text-align: center;
                font-size: 15px;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);
            }
            .second-desc {
                text-align: center;
                margin-top: 8px;
                line-height: 12px;
                font-size: 11px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        
        .second-right-box {
            .second-content {
                box-sizing: border-box;
                background-color: rgba(255, 255, 255, 0.6);
                border-radius: 8px;
                padding: 16px;
                box-shadow: 0 10px 20px 0 rgba(208, 213, 225, 0.2);
                cursor: pointer;
                // &:hover {
                //     background-color: rgba(255, 255, 255, 1);
                // }
                .second-content-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-weight: 587;
                    font-size: 12px;
                    line-height: 14px;
                    color: rgba(23, 15, 73, 1);
                    padding-bottom: 10px;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
                .second-content-desc {
                    text-align: justify;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    color: rgba(23, 15, 73, 0.8);
                }
            }
        }
        
    }
    .third {
        padding: 40px 16px;
        .third-title {
            .title {
                font-weight: 749;
                font-size: 15px;
                text-align: center;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                margin-top: 12px;
                font-weight: 400;
                font-size: 11px;
                text-align: center;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        .third-content {
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            .flex-box {
                display: flex;
            }
            .item {
                flex: 1;
                cursor: pointer;
                margin-top: 32px;
                position: relative;
                background-color: rgba(249, 250, 251, 1);
                padding: 32px 8px 12px 8px;
                border-radius: 8px;
                
                // width: 360px;
                // height: 257px;
                // &:hover {
                //     background-color: rgba(255, 255, 255, 1);
                //     box-shadow: 0px 12px 16px -4px rgba(137,140,149,0.08), 0px 4px 4px 0px rgba(137,140,149,0.03);
                // }
                .icon-box {
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    left: 8px;
                    top: -16px;
                    background-color: rgba(255, 255, 255, 1);
                    box-shadow: 4px 8px 20px 0 rgba(107, 130, 190, 0.2);
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 22px;
                        height: 22px;
                    }
                }
                .title {
                    font-weight: 693;
                    margin-bottom: 16px;
                    font-weight: 693px;
                    font-size: 13px;
                    color: rgba(23, 15, 73, 1);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .desc {
                    text-align: justify;
                    font-size: 14px;
                    line-height: 22px;
                    color:rgba(23, 15, 73, 0.8);
                }
            }
        }
    }
    .fourthly {
        box-sizing: border-box;
        position: relative;
        height: 500px;
        img {
            width: 100%;
            height: 500px;
            object-fit: cover;
        }
        // background-image: url('../../imgs//new/bg1.svg');
        // background-repeat: no-repeat;
        // background-size: cover;
        .fourthly-title {
            position: absolute;
            top: 60px;
            width: 100%;
            .title {
                width: 100%;
                font-size: 15px;
                font-style: normal;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);
                text-align: center;
            }
            .desc {
                margin-top: 12px;
                width: 100%;
                font-size: 11px;
                font-weight: 443;
                color: rgba(23, 15, 73, 0.6);
                text-align: center;
            }
        }
        .fourthly-content {
            box-sizing: border-box;
            width: 100%;
            padding: 0 8px;
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .m-r-8 {
                margin-right: 8px;
            }
            .m-b-20 {
                margin-bottom: 20px;
            }
            .m-b-60 {
                margin-bottom: 60px;
            }
            .item {
                box-sizing: border-box;
                width: 100%;
                padding: 30px 10px;
                cursor: pointer;
                border-radius: 8px;

                background: rgba(255,255,255,0.6);
                box-shadow: 0px 10px 20px 0px rgba(208,213,225,0.2);
                border: 1px solid #FFFFFF;
                // &:hover{
                //     background-color: rgba(255, 255, 255, 1);
                //     box-shadow: 0px 4px 40px #F0F4FF;
                // }
                .title {
                    color: #170F49;
                    font-size: 12px;
                    font-weight: 693;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    img {
                        width: 12px;
                        height: 12px;
                        margin-right: 4px;
                    }
                }
                .desc {
                    width: 100%;
                    color: rgba(23, 15, 73, 0.80);
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 11px;
                    font-weight: 398;
                }
                .list {
                    .list-item-box {
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        .list-item {
                            margin-bottom: 5px;
                            color: rgba(23, 15, 73, 0.80);
                            font-weight: 398;
                            font-size: 8px;
                            padding: 5px 5px;
                            border-radius: 999px;
                            border: 0.5px solid rgba(23, 15, 73, 0.40);
                            &:not(:last-child) {
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
    .fifth {
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 0 12px;
        .fifth-title-box {
            .title {
                font-size: 15px;
                font-style: normal;
                font-weight: 749;
                color: rgba(23, 15, 73, 1);
                text-align: center;
                margin-bottom: 8px;
            }
            .desc {
                padding: 0 32px;
                line-height: 20px;
                font-size: 11px;
                font-style: normal;
                font-weight: 443;
                color: rgba(23, 15, 73, 0.6);
                text-align: center;
                margin-bottom: 25px;
            }
        }
        .swiper-box {
            width: 155px;
            display: inline-block;
            scroll-snap-align: center;
            // height: 480px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .swiper-img {
                width: 155px;
            }
            .swiper-content {
                position: relative;
                top:-18px;
                border-radius: 6px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.90) 0%, #FFF 100%);
                backdrop-filter: blur(6.5px);
                box-sizing: border-box;
                width: 155px;
                padding: 10px;
                box-shadow: 0px 6px 8px -2px rgba(137, 140, 149, 0.08);
                .title {
                    border-radius: 4px;
                    width: 28px;
                    background: rgba(64, 96, 255, 0.08);
                    color: rgba(64, 96, 255, 1);
                    font-size: 7px;
                    padding: 4px;
                }
                .desc {
                    font-size: 10px;
                    font-weight: 489;
                    line-height: 18px;
                    color: rgba(23, 15, 73, 1);
                    padding: 6px 0;
                }
                .time {
                    display: flex;
                    align-items: center;
                    font-size: 9px;
                    color: rgba(23, 15, 73, 0.6);
                    img {
                        margin-right: 4px;
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}