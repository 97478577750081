.contact-us {
    .contact-header {
        position: relative;
        height: 463px;
        width: 100%;
        background-image: linear-gradient(to bottom, #E8E9ED, #DFE1E8);
        .contact-header-img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .contact-header-content {
            position: absolute;
            top: 88px;
            left: 120px;
            .title {
                padding-top: 50px;
                width: 40%;
                font-size: 28px;
                font-style: normal;
                font-weight: 693;
                line-height: 180%;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                width: 480px;
                margin-top: 16px;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
    }
    .contact-first {
        margin-bottom: 144px;
        padding: 0 120px;
        margin-top: 96px;
        .title {
            text-align: center;
            font-size: 36px;
            font-weight: 749;
            line-height: 52px;
            color: rgba(23, 15, 73, 1);
            margin-bottom: 72px;
        }
        .position-box {
            .item {
                border-radius: 16px;
                background: #F9FAFB;
                display: flex;
                justify-content: space-between;
                padding: 40px;
                .left {
                    .title-box {
                        display: flex;
                        align-items: center;
                        .job-title {
                            display: inline-block;
                            font-size: 24px;
                            font-weight: 693;
                            color: rgba(23, 15, 73, 1);
                        }
                        .social-recruitment {
                            margin-left: 20px;
                            display: inline-block;
                            padding: 8px;
                            border-radius: 4px;
                            background: rgba(64, 96, 255, 0.08);
                            color: rgba(64, 96, 255, 1);
                        }
                    }
                    .desc-box {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        margin-top: 26px;
                        .demand {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-right: 32px;
                            img {
                                width: 20px;
                                height: 20px;
                                margin-right: 6px;
                            }
                            span {
                                font-size: 14px;
                                font-weight: 489;
                                line-height: 180%;
                                color: rgba(23, 15, 73, 1);
                            }
                        }
                    }
                }
                .right {
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 20px;
                        font-weight: 587;
                        color:rgba(64, 96, 255, 1);
                        cursor: pointer;
                    }
                    img {
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .contact-second {
        width: 1272px;
        height: 507px;
        margin: auto auto 96px auto;
        position: relative;
        img {
            width: 1272px;
            height: 507px;
        }
        .contact-second-content {
            width: 680px;
            position: absolute;
            top: 70px;
            right: 40px;
            font-family: Dream Han Sans CN;
            font-size: 18px;
            font-weight: 398;
            line-height: 36px;
            text-align: left;
            color: rgba(23, 15, 73, 0.8);
            .title {
                font-family: Dream Han Sans CN;
                font-size: 30px;
                font-weight: 749;
                line-height: 36.01px;
                text-align: left;
                padding-bottom: 24px;
            }
            .label-title {
                font-family: Dream Han Sans CN;
                font-size: 18px;
                font-weight: 693;
                line-height: 36px;
                text-align: left;
            }
        }
    }
}

.drawer-content {
    padding: 60px;
    .drawer-content-header {
        .title {
            font-size: 32px;
            font-weight: 778;
            line-height: 52px;
            color: rgba(23, 15, 73, 1);
        }
        .subhead {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .desc-box {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                margin-top: 26px;
                .demand {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 32px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        font-size: 14px;
                        font-weight: 489;
                        line-height: 180%;
                        color: rgba(23, 15, 73, 1);
                    }
                }
            }
            .email-box {
                display: flex;
                align-items: center;
                .email-icon {
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                }
                .email-title {
                    font-size: 14px;
                    font-weight: 587;
                    line-height: 180%;
                    color: rgba(64, 96, 255, 1);
                }
            }
        }
    }
    .drawer-content-item {
        margin-bottom: 30px;
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            img {
                width: 20px;
                height: 20px;
            }
            span {
                padding-left: 8px;
                color: rgba(23, 15, 73, 1);
                font-size: 18px;
                font-weight: 693;
            }
        }
        .jd {
            .jd-item {
                font-size: 16px;
                font-weight: 398;
                line-height: 180%;
                color: rgba(23, 15, 73, 0.8);
            }
        }
    }
    .welcome {
        font-size: 16px;
        font-weight: 587;
        line-height: 180%;
        color: rgba(23, 15, 73, 1);
    }
}