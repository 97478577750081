.mobile-contact-us {
    .contact-header {
        margin-top: -72px;
        position: relative;
        height: 363px;
        width: 100%;
        background-image: linear-gradient(to bottom, #E8E9ED, #DFE1E8);
        .contact-header-img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 200px;
            height: 200px;
        }
        .contact-header-content {
            padding: 0 16px;
            .title {
                padding-top: 100px;
                font-size: 16px;
                font-style: normal;
                font-weight: 693;
                line-height: 28px;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                width: 480px;
                margin-top: 16px;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
    }
    .contact-first {
        margin-bottom: 60px;
        padding: 0 16px;
        margin-top: 60px;
        .title {
            text-align: center;
            font-size: 18px;
            font-weight: 749;
            line-height: 52px;
            color: rgba(23, 15, 73, 1);
            margin-bottom: 32px;
        }
        .position-box {
            .item {
                border-radius: 16px;
                background: #F9FAFB;
                display: flex;
                justify-content: space-between;
                padding: 15px 20px;
                .left {
                    .title-box {
                        display: flex;
                        align-items: center;
                        .job-title {
                            display: inline-block;
                            font-size: 12px;
                            font-weight: 693;
                            color: rgba(23, 15, 73, 1);
                        }
                        .social-recruitment {
                            font-size: 9px;
                            margin-left: 10px;
                            display: inline-block;
                            padding: 4px;
                            border-radius: 4px;
                            background: rgba(64, 96, 255, 0.08);
                            color: rgba(64, 96, 255, 1);
                        }
                    }
                    .desc-box {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-wrap: nowrap;
                        margin-top: 8px;
                        .demand {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-right: 12px;
                            img {
                                width: 10px;
                                height: 10px;
                                margin-right: 4px;
                            }
                            span {
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 180%;
                                color: rgba(23, 15, 73, 1);
                            }
                        }
                    }
                }
                .right {
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 13px;
                        font-weight: 587;
                        color:rgba(64, 96, 255, 1);
                        cursor: pointer;
                    }
                    img {
                        width: 10px;
                        height: 10px;
                        margin-left: 4px;
                    }
                }
            }
        }
    }
    .contact-second {
        margin: auto auto 96px auto;
        padding: 0 16px;
        width: 318px;
        height: 680px;
        position: relative;
        img {
            width: 318px;
            height: 680px;
        }
        .contact-second-content {
            width: 280px;
            position: absolute;
            top: 70px;
            right: 34px;
            font-family: Dream Han Sans CN;
            font-size: 12px;
            font-weight: 398;
            line-height: 20px;
            text-align: left;
            color: rgba(23, 15, 73, 0.8);
            .title {
                font-family: Dream Han Sans CN;
                font-size: 12px;
                font-weight: 749;
                line-height: 20px;
                text-align: left;
                padding-bottom: 6px;
            }
            .label-title {
                font-family: Dream Han Sans CN;
                font-size: 12px;
                font-weight: 693;
                line-height: 20px;
                text-align: left;
            }
        }
    }
}

.drawer-content {
    padding: 0;
    .drawer-content-header {
        .title {
            font-size: 14px;
            font-weight: 778;
            line-height: 21px;
            color: rgba(23, 15, 73, 1);
        }
        .email-box {
            display: flex;
            align-items: center;
            .email-icon {
                margin-right: 4px;
                width: 10px;
                height: 10px;
            }
            .email-title {
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                color: rgba(64, 96, 255, 1);
            }
        }
        .subhead {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .desc-box {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                margin-top: 13px;
                .demand {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 16px;
                    img {
                        width: 10px;
                        height: 10px;
                        margin-right: 4px;
                    }
                    span {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 22px;
                        color: rgba(23, 15, 73, 1);
                    }
                }
            }
            
        }
    }
    .drawer-content-item {
        margin-bottom: 15px;
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            img {
                width: 10px;
                height: 10px;
            }
            span {
                padding-left: 4px;
                color: rgba(23, 15, 73, 1);
                font-size: 12px;
                font-weight: 500;
            }
        }
        .jd {
            .jd-item {
                font-size: 13px;
                font-weight: 398;
                line-height: 180%;
                color: rgba(23, 15, 73, 0.8);
            }
        }
    }
    .welcome {
        font-size: 13px;
        font-weight: 587;
        line-height: 23px;
        color: rgba(23, 15, 73, 1);
    }
}