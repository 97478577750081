.about-us {
    .about-header {
        position: relative;
        height: 463px;
        width: 100%;
        background-image: linear-gradient(to bottom, #E6EEF6, #E6F3FF);
        .about-header-img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .about-header-content {
            position: absolute;
            top: 88px;
            left: 120px;
            .title {
                font-size: 40px;
                font-weight: 808;
                color: rgba(23, 15, 73, 1);
            }
            .desc {
                width: 480px;
                margin-top: 16px;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
            .button {
                font-size: 30px;
                color: #fff;
                background: linear-gradient(90deg, #0696FF 0%, #4060FF 100%);
                margin-top: 50px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
    .about-us-introduce {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: auto;
        width: 1272px;
        height: 554px;
        top: -50px;
        img {
            width: 100%;
        }
        .about-us-introduce-left-font-box {
            position: absolute;
            left: 105px;
            top: 90px;
            width: 271px;

            .m-tb-62 {
                margin-top: 62px;
                margin-bottom: 62px;
            }
            .left-font-item {
                color: #ffffff;
                text-align: justify;
                .label {
                    font-family: Dream Han Sans CN;
                    font-size: 20px;
                    font-weight: 693;
                    line-height: 24px;
                    text-align: justify;
                    margin-bottom: 8px;
                }
                .desc {
                    font-family: Dream Han Sans CN;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 32.4px;
                    text-align: justify;
                }
            }
        }
        .about-us-introduce-right-font-box {
            position: absolute;
            right: 85px;
            top: 50px;
            width: 640px;
            color: rgba(23, 15, 73, 0.8);
            font-family: Dream Han Sans CN;
            font-size: 16px;
            font-weight: 398;
            line-height: 32px;
            text-align: justify;
        }
    }
    .enterprise-honor {
        margin-bottom: 88px;
        padding: 0 120px;
        .title {
            text-align: center;
            font-size: 36px;
            font-weight: 749;
            line-height: 52px;
            color: rgba(23, 15, 73, 1);
        }
        .desc {
            font-size: 18px;
            font-weight: 443;
            line-height: 36px;
            text-align: center;
            color: rgba(23, 15, 73, 0.6);
        }
    }
    .swiper-box {
        width: 364px;
        display: inline-block;
        scroll-snap-align: center;
        cursor: pointer;
        border-radius: 16px;

        .swiper-img {
            width: 360px;
            height: 274px;
            border-radius: 16px;
        }
        .swiper-content {
            margin-top: 32px;
            text-align: center;
            .title {
                font-size: 20px;
                font-weight: 693;
                color: rgba(23, 15, 73, 1);
            }
            .time {
                margin-bottom: 16px;
                font-size: 14px;
                font-weight: 693;
                color: rgba(64, 96, 255, 1);
            }
        }
    }
    .core-team {
        padding: 0 120px;
        .title {
            text-align: center;
            font-size: 36px;
            font-weight: 749;
            line-height: 52px;
            color: rgba(23, 15, 73, 1);
            margin-bottom: 72px;
        }
        .person {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 120px;
            .introduce {
                flex: 1;
                .top {
                    margin-bottom: 30px;
                    .name {
                        color: #170F49;
                        font-size: 32px;
                        font-weight: 749;
                    }
                    .job-title {
                        margin-left: 16px;
                        font-size: 20px;
                        font-weight: 749;
                        color: rgba(64, 96, 255, 1);
                    }
                }
                .tag-box {
                    display: flex;
                    flex-wrap: wrap;
                    .tag {
                        margin-bottom: 12px;
                        display: inline-block;
                        font-weight: 443;
                        font-size: 14px;
                        color: #001781;
                        padding: 8px 16px;
                        border-radius: 99px;
                        background: rgba(64, 96, 255, 0.08);
                        &:not(:last-child) {
                            margin-right: 16px;
                        }
                    }
                }
                .desc {
                    padding-top: 12px;
                    color: rgba(23, 15, 73, 1);
                    font-size: 16px;
                    font-weight: 443;
                    line-height: 200%;
                }
            }
            .avater {
                width: 485px;
                height: 264px;
                border-radius: 16px;
                // background: #DDE2EC;
            }
            .m-l-60 {
                margin-left: 60px;
            }
            .m-r-60 {
                margin-right: 60px;
            }
        }
    }
    .technical-team-box {
        background-color: rgba(247, 248, 253, 1);
        padding: 60px 30px 60px 120px;
        margin-bottom: 60px;
        .technical-team {
            padding-right: 60px;
            .title {
                text-align: center;
                font-size: 36px;
                font-weight: 749;
                line-height: 52px;
                color: rgba(23, 15, 73, 1);
                margin-bottom: 12px;
            }
            .desc {
                text-align: justify;
                font-size: 18px;
                font-weight: 443;
                line-height: 36px;
                color: rgba(23, 15, 73, 0.6);
            }
        }
        .col-img-6 {
            width: 100%;
            height: auto;
        }
    }
    
    .company-logo {
        margin-bottom: 200px;
        padding: 0 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // .swiper {
        //     .swiper-wrapper {
        //         .swiper-slide {
        //             height: 178px;
        //             width: 240px;
        //             display: flex;
        //             justify-content: center;
                    
        //             &:nth-child(even) {
        //                 align-items: flex-end;
        //             }
        //             &:nth-child(odd) {
        //                 align-items: flex-start;
        //             }
        //         }
        //     }
        // }
        .school {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .company {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
.ant-modal-root {
    .about-us-modal-video {
        .ant-modal-close {
            top: 2px;
            right: 4px !important;
        }

    }
}