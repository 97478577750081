.mobile-page-header {
    box-sizing: border-box;
    height: 72px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    .logo-box {
        display: flex;
        align-items: center;
        width: 80px;
        height: 25px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .menu-icon-box {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.page-header-menu {
    position: fixed;
    top: 72px;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
    padding: 0 32px;
    background: #ffffff;
    .title-link {
        display: block;
        color: #170F49;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        padding: 20px 0;
    }
    .active {
        font-weight: 600;
    }
    .diveder-line {
        margin: 0;
    }
    // .free-button {
    //     margin-top: 36px;
    //     border-radius: 80px 80px 80px 80px;
    //     border: 1px solid #4060FF;
    //     color: #4060FF;
    //     font-size: 16px;
    //     width: 100%;
    //     padding: 14px 0;
    //     text-align: center;
    //     font-weight: 500;
    // }
    // .work-button {
    //     margin-top: 16px;
    //     border-radius: 80px 80px 80px 80px;
    //     background: #4060FF;
    //     color: #ffffff;
    //     font-size: 16px;
    //     width: 100%;
    //     padding: 14px 0;
    //     text-align: center;
    //     font-weight: 500;
    // }
}